@import '../../../theme/main';

@mixin forms-wrapper {
  width: 80%;
  @include flex-column-center-center;

  h3 {
    margin-bottom: 30px;
    margin-top: 35px;
    text-align: center;
    font-size: 1.1rem;
  }

  .formsContent {
    width: 100%;
    margin-bottom: 20px;

    .loginForm {
      display: flex;
      flex-direction: column;

      > div {
        &:first-child {
          margin-bottom: 10px;
        }
        input {
          padding: 10px 5px;
        }
      }
      .rememberMe {
        color: $color_primary;
        span {
          color: $color_primary;
        }
      }

      .submitButton {
        margin-top: 30px;
        @include button-base-blue;
        height: 50px;
        font-size: 1.1em;
      }

      .error {
        margin: 10px 0 0 0;
        color: $color-error;
      }
    }
  }

  .forgotPassword {
    color: $link-color-blue;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 10px;
  }
}

.loginModalContent {
  @include flex-column-center-center;
  margin-top: -20px;
  margin-bottom: 30px;

  .padlock {
    width: 40px;
    object-fit: contain;
  }

  .formsWrapper {
    @include forms-wrapper;
  }

  .footerWrapper {
    width: 80%;
    text-align: center;

    .leftText {
      color: $light-text;
      font-size: 14px;
    }

    .rightText {
      color: $dark-red;
      font-size: 14px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.loginFormInSignup {
  @include forms-wrapper;
  width: 100%;

  h3 {
    color: $white;
  }

  .formsContent {
    .loginForm {
      .rememberMe {
        color: $white;
        span {
          color: $white;
        }
      }

      .submitButton {
        background-color: #bac6f2;
        color: $color-primary;
        font-weight: 700;
      }
    }
  }

  .forgotPassword {
    text-align: center;
  }
}
