@import '../../theme/main';

.urbaGpt {
  &Backdrop {
    position: fixed;
    inset: 0px;
    z-index: 1009;
    background-color: rgba(0, 0, 0, 0.5);

    &.invisible {
      visibility: hidden;
    }
  }

  &Header {
    @include flex-between-center;
    padding: 12px 20px;
    border-radius: 5px 5px 0 0;
    background: $color-primary;
    color: $white;

    h4 {
      margin: 0;
    }
    div {
      @include flex-center-center;
      padding: 5px;
      width: 30px;
      height: 30px;
      background: $color-error;
      border-radius: 5px;
      cursor: pointer;
      svg {
        width: 13px;
        color: $white;
      }
    }
  }

  &Container {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 180px;
    bottom: 135px;
    height: 500px;
    width: 70vw;
    min-width: 510px;
    max-width: 925px;
    z-index: 1010;
    border-radius: 6px 6px 5px 5px;
    background-color: $white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);

    &.visible {
      animation: scale-bottom-right 0.5s ease 0s 1 normal forwards;
    }
    &.invisible {
      animation: scale-bottom-right-reverse 0.5s ease 0s 1 normal forwards;
    }

    .urbaChatBox {
      position: relative;
      display: flex;
      flex-direction: column;
      max-height: 375px;
      height: auto;
      overflow: auto;
      margin-bottom: 10px;

      p {
        margin: 0;
      }
      .question {
        background-color: $urba-gpt-background-question;
        border-radius: 5px;
        padding: 15px 10px;
        margin-right: 30px;
        margin-bottom: 5px;
        width: fit-content;
      }
      .response {
        background-color: $blue-light;
        border-radius: 5px;
        padding: 15px 10px;
        margin-left: 30px;
        margin-bottom: 5px;
        width: fit-content;
        align-self: end;
        div {
          display: inline;
        }
        .blink {
          &::after {
            content: ' ';
            animation: blink-caret 0.75s step-end infinite;
            border-right: 5px solid $grey-color;
          }
        }
      }
    }
    .urbaGptTutorial {
      display: flex;
      flex-direction: column;

      ul {
        background: $blue-light;
        padding: 15px 28px;
        margin: auto;
        margin-bottom: 10px;
        border-radius: 5px;
        width: fit-content;
      }
      .question {
        text-align: start;
        background-color: $urba-gpt-background-question;
        color: $grey-color;
        border: 0;
        border-radius: 5px;
        padding: 15px 10px;
        margin-right: 30px;
        margin-bottom: 5px;
        width: fit-content;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  &Search {
    display: flex;
    align-items: center;

    form {
      display: flex;
      align-items: center;
      width: 100%;

      .textarea {
        resize: none;
        width: 100%;
        padding: 10px 5px;
        color: #354052;
        background-color: white;
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 4px;
        font-size: 14px;
        font-weight: 300;
        &:focus {
          border-color: $color-primary;
        }
      }
    }

    .btn {
      @include flex-center-center;
      cursor: pointer;
      border: none;
      color: $white;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
      border-radius: 4px;
      padding: 8px;
      margin-left: 5px;
    }
    .searchBtn {
      background: $color-primary;
      width: 40px;
      height: 40px;
      text-align: center;
    }

    .deleteBtn {
      background: $color-error;
      width: 40px;
      height: 40px;
      padding: 8px 6px;
    }
    .btnDisabled {
      opacity: 0.6;
      cursor: default;
    }
  }

  &Content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 10px;
    max-height: 446px;
  }

  &MapBtn {
    @include flex-center-center;
    position: absolute;
    bottom: 45px;
    cursor: pointer;
    border: none;
    color: $white;
    background: $color-error;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    padding: 10px;
    padding-left: 50px;
    width: 160px;
    height: 40px;
    border-radius: 5px;
    svg {
      position: absolute;
      left: 15px;
      width: 35px;
      height: 35px;
    }
  }

  .scrollButton {
    @include flex-center-center;
    align-self: flex-end;
    position: sticky;
    bottom: 5px;
    right: 0;
    border: none;
    color: $white;
    cursor: pointer;
    background: $darker-grey;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    padding: 10px;
    width: 40px;
    height: 40px;
    border-radius: 25px;
    opacity: 0.6;

    &.hide {
      visibility: hidden;
    }
  }
}

.loader {
  display: flex;
  justify-content: center;
}

.loader span {
  display: inline-block;
  margin: 0 1px;
  opacity: 0;
  animation: loader-animation 0.75s ease-in-out infinite;
}

.loader span:nth-child(2) {
  animation-delay: 0.1s;
}

.loader span:nth-child(3) {
  animation-delay: 0.2s;
}
