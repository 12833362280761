@import '../../../theme/main';

.tab-subscription {
  h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 35px;
    line-height: 42px;
    color: #353b7d;
    margin-top: 0;
  }
  h2 {
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 24px;
    color: #555980;
    margin-bottom: 25px;
  }
  .tab-body {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}
