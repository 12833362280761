@import '../../../theme/main';

.tab-reporting {
  .tab-inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    h1 {
      font-style: normal;
      font-weight: 800;
      font-size: 35px;
      line-height: 42px;
      color: #353b7d;
      margin-top: 0;
    }
    .search-mode {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      p {
        margin: 0 10px;
      }
      button {
        min-width: 150px;
        background-color: $white;
        color: $black;

        &.selected {
          background-color: $color-primary;
          color: $white;
        }
      }
    }
  }
  .tab-body {
    .reporting-table-action {
      @include flex-between-start;
      width: 100%;
      margin-bottom: 10px;

      button {
        @include button-base-blue;
        font-size: 16px;
        width: 210px;
        height: 35px;
      }
      &-filter {
        @include flex-center-start;
        max-width: 600px;

        &-fields {
          @include flex-column-center-center;
          max-width: 600px;

          > div {
            @include flex-center-center;

            .MuiFormControl-root {
              min-width: 150px;
              max-width: 2000px;
              margin: 0 8px;

              .MuiFormHelperText-root.Mui-error {
                margin: 0;
                color: $color-error;
                line-height: 14px;
              }

              .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
                border-color: $color-error;
              }
            }
          }

          p {
            color: $color-error;
            line-height: 15px;
          }

          .error-between-dates {
            margin: 5px 0 0 0;
            color: $color-error;
            font-size: 0.9em;
            visibility: hidden;

            &.visible {
              visibility: visible;
            }
          }
        }
        button {
          margin-left: 10px;
          max-width: 150px;
        }
      }
    }

    .reporting-individual {
      @include flex-column-center-center;
      h3,
      h4,
      p {
        text-align: center;
      }
      .graph-container {
        height: 400px;
      }
      .pie-graph {
        width: 600px;
      }
      .line-graph {
        width: 800px;
      }
      .table-plots-individual {
        width: 100%;
      }
    }
    .icon {
      color: $color-primary;
      width: 30px;
      height: 30px;
      cursor: pointer;
      &.down {
        transform: rotate(180deg);
      }
    }
    .collapse-table-action {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      &-filter {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .download-icon {
          svg {
            height: 20px;
            width: 20px;
            color: #4e55a2;
            padding: 6px;
            display: inline-block;
            vertical-align: middle;
          }
          &:hover {
            cursor: pointer;
          }
        }
        .filter-sort-bloc {
          width: 250px;
        }
      }
    }

    .reporting-loader {
      p {
        text-align: center;
      }
    }
  }
}
