@import '../../../theme/main';

.checkContainer {
  .checkLabel {
    span {
      font-size: 14px;
    }
  }
}

.radioGroupContainer {
  margin: 10px 0;
  background-color: inherit;
  border-color: whitesmoke;
  border-radius: 5px;

  &.error {
    border-color: $input-has-error-border;
    background-color: $input-has-error-bkg;
    p {
      margin: 0;
      text-align: left;
      font-size: 0.75rem;
      font-style: italic;
      color: $color-error;
    }
  }

  .groupLabel {
    color: $color-primary;
  }
  .radioGroup {
    .radioLabel {
    }
  }
}
