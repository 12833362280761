@import '../../../theme/main';

.search-company-name-modal {
  .MuiPaper-root {
    height: 620px;
  }
  .MuiPaper-rounded {
    border-radius: 10px !important;
    width: 800px;
    margin: 0px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.565068);
  }

  &-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    .close-cross {
      cursor: pointer;
      display: flex;
      justify-content: flex-end;

      svg {
        width: 20px;
        margin: 20px 20px 0 0;
      }
    }

    h3 {
      text-align: center;
    }

    .search-engine {
      display: flex;
      align-items: center;

      .text-field {
        margin: 20px;
        margin-right: 10px;
        width: 30%;
      }
    }

    .to-many-results {
      text-align: center;
      margin-top: 40px;
      font-size: 20px;
      line-height: 30px;
    }
    .MuiTableBody-root {
      .MuiTableRow-root {
        &:hover {
          background-color: $background-grey;
          cursor: pointer;
        }

        &.loader-row {
          height: 300px;
        }

        .MuiTableCell-root {
          &:nth-child(2) {
            text-align: center;
          }
        }
      }
    }

    th {
      text-align: center;
    }

    td {
      padding: 5px;
    }
  }

  .research-contact-btn {
    @include button-base-blue;
  }
}
