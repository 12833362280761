@import '../../../../../theme/main';

.tick {
  &Green {
    fill: #2ed753;
  }
  &Grey {
    fill: #e6e6e6;
  }
}
