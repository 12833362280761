@import '../../theme/main';

.main-wrapper-account {
  background-color: $white;
  min-height: 100vh;
  max-width: 1280px;
  margin: auto;
  display: flex;
  flex-direction: column;

  .urbn-loader-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: hsla(120, 13%, 10%, 0.7);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 10000;
    height: 100vh;
  }
  .header-wrapper {
    background-color: $white;
    display: flex;
    height: 100px;
    align-items: center;
    padding: 0 70px;
    justify-content: space-between;
    .header-col-left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      img {
        width: 216px;
        height: 46px;
        margin: 17px 5px;
      }
    }
    .header-col-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      p {
        font-style: normal;
        color: #353b7d;
        font-family: 'Lato', sans-serif;
      }
      .para-head {
        font-style: normal;
        font-weight: 300;
        font-size: 22px;
        text-align: left;
        color: #353b7d;
        font-family: 'Lato', sans-serif;
        margin-left: 20px;
        @media screen and (max-width: 1018px) {
          font-size: 18px;
        }
      }
      button {
        height: 35px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #ffffff;
        background: $color-success;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
        border-radius: 2px;
        border: none;
        cursor: pointer;
        margin-left: 20px;
        padding: 0 15px;
        font-family: 'Lato', sans-serif;
      }
      .logout {
        @include flex-center-center;
        height: 35px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #ffffff;
        background: #d2112f;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
        border-radius: 2px;
        cursor: pointer;
        margin: 0;
        margin-left: 20px;
        padding: 0 15px;
        font-family: 'Lato', sans-serif;
      }
      .change-company {
        width: 155px;
        height: 35px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #ffffff;
        background: #d2112f;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
        border-radius: 2px;
        border: none;
        cursor: pointer;
        margin-left: 20px;
        margin-right: 0;
        font-family: 'Lato', sans-serif;
      }
    }
    img {
      width: 27px;
      height: 29px;
      cursor: pointer;
    }
  }
  .body-wrapper {
    padding: 15px 70px 20px 70px;
    background-color: #ffffff;
    .MuiAppBar-colorDefault {
      background-color: #ffffff;
      box-shadow: none;
    }
    .MuiAppBar-colorDefault {
      background-color: #ffffff;
    }
    .MuiTab-textColorPrimary {
      color: #4e55a2;
      text-transform: capitalize;
    }
  }
  .footer-wrapper {
    background-image: url('../../assets/images/diagonal.png');
    height: 200px;
    background-position: center;
    background-color: #ffffff;
    width: 100%;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    .inner-footer {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      h3 {
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: #353b7d;
        margin: 0px;
      }
      .legal-mentions-button {
        color: $color-primary;
        margin: 10px;
        text-decoration: none;
        border-radius: 5px;
        padding: 10px;
        width: 220px;
      }
      .inner-para {
        font-style: normal;
        font-weight: 300;
        font-size: 21px;
        line-height: 24px;
        text-align: center;
        color: #555980;
        margin: 5px 0px 20px;
      }
      button {
        background: #4e55a2;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
        border-radius: 2px;
        width: 271px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #ffffff;
        border: none;
        padding: 15px 0px;
        cursor: pointer;
      }
    }
  }
}
