@import '../../../theme/main';

.MuiDialogContent-root {
  padding: 0 !important;

  .modal-box-wrapper {
    display: flex;
    flex-direction: row;
    height: 660px;
    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      height: auto;
    }

    .hubspot {
      color: $color-primary;

      h2 {
        padding: 110px 0 40px 0;
        font-size: 16px;
        font-weight: 700;
        margin: 0;
        color: inherit;
        text-align: center;
      }

      p {
        margin-top: 40px;
        line-height: 18px;
        font-size: 16px;
      }

      .houses-img-wrapper {
        width: 90%;
        object-fit: contain;
      }
    }

    h2 {
      padding: 30px 0px;
      font-size: 16px;
      font-weight: 700;
      color: #fff;
      margin: 0;
    }

    .MuiInputBase-input {
      line-height: normal;
    }

    .modal-login-panel {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px 50px 0px;
      width: 45%;
      background: #4f57a0;
      @media screen and (max-width: 768px) {
        width: auto;
      }

      .title-pin {
        img {
          padding-top: 40px;
          height: 118px;
        }
      }
    }

    .modal-right-panel {
      padding: 50px 50px;
      width: 55%;
      background: $ghost-white;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media screen and (max-width: 768px) {
        width: auto;
      }

      .submit-btn {
        @include button-base-blue;
        margin-top: -17px;
        width: 100%;
        height: 50px;
        z-index: 2;
      }
    }
  }
}
