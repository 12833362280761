@import '../../../theme/main';

.uploadModal {
  .uploadModalContent {
    @include flex-column-center-center;

    input[type='file'] {
      display: none;
    }

    .uploadContentBody {
      @include flex-column-center-center;
      height: 300px;
      width: 100%;

      > p {
        font-style: italic;
        font-size: $small-font-size;
        margin: 0;
        text-align: center;
      }
      .imageContainer {
        @include flex-column-start-center;
        height: 260px;
        width: 100%;
        margin-bottom: 20px;

        h4 {
          margin-bottom: 30px;
        }

        .deleteButton {
          height: 30px;
          margin-top: 30px;
        }
        p {
          text-align: center;

          &.errorText {
            color: $color-error;
          }
        }
      }
      .button {
        margin-top: 10px;
      }
      .successBody,
      .failBody {
        @include flex-column-center-center;
        flex: 1;
        height: 100%;

        .icon {
          margin-bottom: 20px;
        }
      }
    }
    .failBody {
      color: $color-error;
    }

    .button {
      margin-top: 20px;
    }
  }
}
