@import '../../../theme/main';

.pluOtherContainer {
  @include environment-container;

  .subCategory {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 24px;

    .header {
      padding: 0 5px;
      margin-top: 1px;
      display: flex;
      align-items: center;

      &.openedHeader {
        background-color: $light-gray-bg;
        border-radius: 5px;
      }

      .isTitle {
        font-weight: 700;
      }

      label {
        flex-grow: 1;
      }

      .expandIcon {
        cursor: pointer;
      }
      .isOpen {
        transform: rotate(180deg);
      }
    }

    .mapboxItem {
      padding: 0 5px;
      margin-top: 1px;
      display: flex;
      align-items: center;
    }

    .childs {
      padding-left: 20px;
      min-height: 24px;
    }
  }
}
