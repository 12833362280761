@import '../../../../../../theme/main';

.leaflet-popup-content {
  width: auto;
}
.area-measure-popup-container {
  min-width: 300px;
  color: $color-primary;

  hr {
    border-top: 1px solid #ddd;
  }

  p {
    padding: 0;
    margin: 0;
  }

  h3 {
    text-align: center;
  }

  .area-measure-popup-result {
  }

  .area-measure-popup-action-section {
    display: flex;
    justify-content: flex-end;

    p {
      display: flex;
      align-items: center;
      cursor: pointer;
      line-height: 20px;
      &:last-child {
        margin-left: 15px;
      }
      img {
        width: 12px;
        height: 12px;
        margin-right: 5px;
      }
    }
  }
}
