@import '../../../theme/variables';

.add-user-modal {
  .MuiPaper-rounded {
    border-radius: 10px !important;
    max-width: 590px;
    width: 450px;
    margin: 0px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.565068);
  }
  .add-user-modal-container {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 30px !important;
    position: relative;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.565068);
    border: none;
    .xicon-wrapper {
      cursor: pointer;
      position: absolute;
      top: 18px;
      right: 20px;
      svg {
        font-size: xx-large;
        fill: #bababa;
        width: 28px;
        height: 28px;
      }
    }
    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 19px;
      line-height: 23px;
      color: #4e55a2;
      letter-spacing: normal;
      text-transform: none;
      margin-bottom: 30px;
    }
    .text-field {
      margin-top: 15px;
      .MuiFormControl-root {
        box-shadow: 0 0 3px 1px rgb(0 0 0 / 6%);
        border-color: #e4e6e8;
        background-color: #f1f4f8;

        .MuiOutlinedInput-root {
          border-radius: 5px;
          background: #ffffff;
          border: 1px solid #e4e6e8;

          &.Mui-error .MuiOutlinedInput-notchedOutline {
            border-color: $color-error;
          }

          &:hover {
            color: #4e55a2;
          }

          .MuiOutlinedInput-input {
            height: 30px;
            color: #4e55a2;
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            background: transparent;
          }
        }
        .MuiInputLabel-outlined {
          color: #4e55a2;
          font-weight: 300;
          font-size: 14px;
          margin-left: 5px;
        }

        .MuiFormHelperText-root {
          color: $color-error;
          font-style: italic;
          font-size: 10px;
          margin-left: 3px;
          line-height: 12px;
        }
      }
    }
  }

  .btn {
    background: #75cc8b;
    border-radius: 2px;
    width: 237px;
    height: 48px;
    cursor: pointer;
    margin-top: 22px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #ffffff;
    border: none;
    text-transform: UPPERCASE;
    width: 300px;
  }
}
