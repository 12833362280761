@import '../../../../theme/main';

.subscriptionCard {
  color: #555980;
  background: #ffffff;
  border: 1px solid #d8e3f2;
  box-sizing: border-box;
  margin-bottom: 26px;
  padding: 30px;
  border-radius: 5px;

  h2 {
    margin-top: 5px;
  }
  ul {
    padding-left: 20px;
  }
  &Body {
    .infos {
      display: flex;
    }
    .sec {
      width: 400px;
      p {
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        margin: 0;
      }
      strong {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
      }
      .readMore {
        display: flex;
        justify-content: center;
        letter-spacing: 2px;
        padding: 0 2px;
        border: 1px solid $color-primary;
        border-radius: 7px;
        margin-left: 5px;
        width: 30px;
        height: 20px;
        cursor: pointer;
        font-size: 16px;
      }
      .flexDiv {
        display: flex;
      }
      .cityName {
        span {
          text-transform: lowercase;
        }
        text-transform: capitalize;
      }
    }
  }
}
