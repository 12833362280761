@import '../../theme/main';

.main-wrapper-dashboard {
  background-color: $white;
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: 1280px;
  margin: auto;
  .header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    padding: 10px 70px;
    background-color: #ffffff;
    .header-col-left {
      width: 62%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      img {
        width: 216px;
        height: 46px;
        margin: 17px 0px;
      }
    }
    button {
      width: 185px;
      height: 35px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #ffffff;
      background: #d2112f;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
      border-radius: 2px;
      border: none;
      cursor: pointer;
      margin-left: 24px;
      margin-right: 50px;
      font-family: 'Lato', sans-serif;
    }
  }
  .MuiTabs-root {
    padding: 0 70px;

    .MuiTabs-flexContainer {
      > button {
        width: 20%;
      }
    }
  }
  .MuiAppBar-colorDefault {
    background-color: #ffffff;
    box-shadow: none;
  }

  .MuiAppBar-colorDefault {
    background-color: #ffffff;
  }

  .MuiTab-textColorPrimary {
    color: #4e55a2;
    text-transform: capitalize;
  }
  .body-wrapper {
    padding: 15px 70px 20px 70px;
    background-color: #ffffff;
    width: 100%;

    .MuiTableCell-root {
      text-align: center;
    }

    > div {
      height: 100%;
    }
    .MuiBox-root {
      padding: 0;
    }
    th {
      background-color: #e4e6e8;
    }

    .clickable {
      cursor: pointer;
      font-weight: 700 !important;
      text-decoration: underline;

      &:hover {
        background-color: $background-grey !important;
      }
    }

    .row {
      @include table-row-border-bottom;

      .MuiTableCell-root {
        padding-top: 8px;
        padding-bottom: 8px;
      }
      td {
        border-bottom: none !important;
        font-style: normal !important;
        font-weight: 500;
        font-size: 12px !important;
        color: $table-row-color !important;
      }
    }
  }
}
.dashboard-scroll {
  overflow: auto;
}

.no-plot-studies {
  text-align: center;
  padding: 10px 0;
  font-weight: 600;
  color: $black;

  &.p-max {
    width: 100%;
  }
}

.filter-container-plot {
  display: flex;
  justify-content: flex-end;
}
