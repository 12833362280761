/*********************************************************
**                                                      **
**       Leaflet Plugin "Leaflet.PolylineMeasure"       **
**       File "Leaflet.PolylineMeasure.css"             **
**       Date: 2021-10-05                               **
**                                                      **
*********************************************************/
@import '../../../../../../theme/main';

.leaflet-control {
  cursor: pointer;
}

a.polyline-measure-controlOnBgColor,
a.polyline-measure-controlOnBgColor:hover {
  color: $orange !important;
}

a.polyline-measure-unicode-icon {
  border-top: 1px solid #ddd !important;
  margin-top: 5px;
}

a.polyline-measure-clearControl:active {
  background-color: #f88;
}

a.polyline-measure-clearControl {
  border-top: none !important;
  margin-top: 0;
}

.polyline-measure-tooltip {
  font:
    10px Arial,
    Helvetica,
    sans-serif;
  line-height: 10px;
  background-color: $white;
  border-radius: 3px;
  box-shadow: 1px 1px 4px #888;
  margin: 0;
  padding: 2px;
  width: auto !important;
  height: auto !important;
  white-space: nowrap;
  text-align: right;
}

.polyline-measure-tooltip-end {
  background-color: $white;
}

.polyline-measure-tooltip-total {
  color: $color-primary;
  font-weight: bold;
}

.polyline-measure-tooltip-difference {
  color: $red;
  font-style: italic;
}

.polyline-measure-popupTooltip {
  font:
    11px Arial,
    Helvetica,
    sans-serif;
  line-height: 11px;
  display: none;
}

.polyline-measure-arrow {
  font-size: 16px;
  line-height: 16px;
  vertical-align: top;
  color: #db4a29;
}
