@import '../../../theme/main';

.loadError {
  text-align: center;
  font-weight: 700;
  margin: 30px 0;
}

.dpeLegend {
  min-height: 201px;
  @include flex-center-center;
  flex-direction: column;

  h4 {
    margin-bottom: 10px !important;
  }

  h5 {
    line-height: 20px;
  }

  .loadingText {
    margin: 0;
    margin-bottom: 10px;
    span {
      font-weight: 700;
    }
  }
  .filtersTitle {
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
    font-style: italic;
    width: 100%;
  }

  .noCity {
    text-align: center;
    margin-top: 60px;
    font-size: 1.1em;
    line-height: 20px;
  }

  > div {
    flex-grow: 1;
    @include flex-center-center;
    flex-direction: column;
  }
  > ul {
    padding: 0;
    margin: 0 0 10px 0;
    display: block;

    li {
      display: flex;
      align-items: center;

      span:first-child {
        padding: 2px;
      }

      p {
        margin: 0 0 0 10px;
        line-height: 15px;
      }

      ul {
        margin: 0 0 0 10px;
        padding-left: 15px;
        li {
          span:first-child {
            padding: 0;
          }

          p {
            font-style: italic;
            font-size: 0.9em;

            span {
              font-size: 0.9em;
            }
          }
        }
      }
    }
  }
  hr {
    width: 100%;
  }

  .row {
    @include flex-center-center;
    flex-direction: row;
    width: 100%;

    .field {
      width: 50%;
      margin: 4px 0;

      .hasError {
        fieldset {
          border-color: $input-has-error-border !important;
        }
        input {
          background-color: $input-has-error-bkg;
        }
      }

      .label {
        &.isLabelHover {
          @include flex-start-center;
        }

        font-size: 14px;
        margin-bottom: 2px;
        svg {
          cursor: help;
          margin-left: 5px;
          width: 13px;
          height: 13px;
        }
        span {
          font-size: 14px;
        }

        &Small {
          font-size: 0.9em;
        }
      }

      input {
        line-height: 25px;
      }

      &:first-child {
        margin-right: 5px;
      }
    }
  }

  form {
    .datesInputs {
      display: flex;
      flex-direction: column;

      > div {
        @include flex-between-center;

        .withSpaces {
          margin: 0 5px;
        }

        .dateField {
          // margin: 5px;
          .MuiFormControl-root {
            min-width: 150px;
            max-width: 2000px;
            margin: 0 8px;
            .MuiFormHelperText-root.Mui-error {
              margin: 0;
              color: $color-error;
              line-height: 14px;
            }
            .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
              border-color: $color-error;
            }
          }
        }
      }
      p {
        font-size: 14px;
        margin-bottom: 2px;
      }
      .errorBetweenDates {
        margin: 5px 0 0 0;
        color: $color-error;
        font-size: 0.9em;
        visibility: hidden;
        &.visible {
          visibility: visible;
        }
      }
    }
    button {
      margin: 5px auto 0 auto;
      min-width: 100px;
    }
  }
}

.dpeMarkerBase {
  background-color: none;
}

.dpePoint {
  margin-left: 5px;
  height: 14px;
  width: 14px;
  transform: rotate(45deg);
  border: 2px solid orange;

  &.aClass {
    background-color: rgb(87, 163, 39);
  }
  &.bClass {
    background-color: rgb(103, 169, 35);
  }
  &.cClass {
    background-color: rgb(162, 194, 21);
  }
  &.dClass {
    background-color: rgb(236, 226, 33);
  }
  &.eClass {
    background-color: rgb(241, 164, 32);
  }
  &.fClass {
    background-color: rgb(215, 60, 32);
  }
  &.gClass {
    background-color: rgb(210, 11, 34);
  }

  &.isRecent {
    border: 2px solid greenyellow;
  }
}

.popup {
  width: 341px;

  &Container {
    p {
      margin: 0;
      padding: 0;
    }

    .header {
      width: 100%;

      @include flex-center-center;
      border-bottom: none;
      cursor: pointer;

      > div {
        border: 1px solid $color-primary;
      }

      .buttons {
        @include flex-center-center;
        width: 15px;
        font-weight: 700;
        font-size: 1.2em;
        line-height: 36px;
        background-color: lightgrey;
        height: 50px;
        cursor: pointer;

        &:first-child {
          border-right: none;
        }

        &:last-child {
          border-left: none;
        }

        &.disabled {
          color: white;
        }
      }

      .tabContents {
        flex-grow: 1;
        @include flex-center-center;

        .tabContent {
          width: 100%;
          height: 48px;
          padding: 8px 0;
          text-align: center;
          font-weight: 700;
          font-size: 1.2em;
          border-right: 1px solid $color-primary;

          > p {
            margin: 0;
          }

          &.selected {
            background-color: $color-primary;
            color: $white;
          }

          &:last-child {
            border-right: none;
            border-left: none;
          }

          .dpeClass {
            margin: auto;
            margin-bottom: 2px;
            @include flex-center-center;
            // border-radius: 50%;
            transform: rotate(45deg);
            width: 16px;
            height: 16px;
            color: black;

            p {
              transform: rotate(-45deg);
            }
            &.aClass {
              background-color: rgb(87, 163, 39);
            }
            &.bClass {
              background-color: rgb(103, 169, 35);
            }
            &.cClass {
              background-color: rgb(162, 194, 21);
            }
            &.dClass {
              background-color: rgb(236, 226, 33);
            }
            &.eClass {
              background-color: rgb(241, 164, 32);
            }
            &.fClass {
              background-color: rgb(215, 60, 32);
            }
            &.gClass {
              background-color: rgb(210, 11, 34);
            }

            &.isRecent {
              border: 2px solid greenyellow;
            }
          }
        }
      }
    }

    .dpePopupContent {
      border: 1px solid $color-primary;
      border-top: none;
      padding: 10px;

      p,
      li {
        font-weight: 700;

        span {
          font-weight: 400;
        }
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        padding-left: 10px;
        display: flex;
        flex-direction: column;
      }

      p {
        margin: 0;
        padding: 1px;
      }
    }
  }
}

// triangle
//   .dpe {
//     height: 0;
//     width: 0;
//     // border-radius: 50%;
//     border-left: 8px solid transparent;
//     border-right: 8px solid transparent;
//     border-bottom: 16px solid rgb(130, 247, 130);
//   }
