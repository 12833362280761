@import '../../../theme/main';

.colorWrapper {
  // for mui tooltip
  &.tooltip {
    @each $class, $color in $colorList {
      &.#{'c' + $class} {
        > div {
          color: $color;
        }
      }
      &.#{'bg' + $class} {
        > div {
          background-color: $color;
          > span {
            color: $color;
          }
        }
      }
    }
  }

  &.btn,
  &.icon {
    @each $class, $color in $colorList {
      &.#{'c' + $class} {
        color: $color;
      }
      &.#{'bg' + $class} {
        background-color: $color;
        border: 1px solid $color;

        &:hover:not(:disabled) {
          background-color: adjust-brightness($color, 4);
        }

        &:active:not(:disabled) {
          $value: adjust-brightness($color, 10);
          background-color: $value;
          border-color: $value;
        }

        &:disabled {
          $value: adjust-brightness($color, -10);
          background-color: $value;
          border-color: $value;
          cursor: default;
        }
      }
    }
  }

  &.icon {
    @each $class, $color in $colorList {
      &.#{'c' + $class} {
        svg {
          path,
          rect,
          circle {
            fill: $color;
          }
        }
      }
    }
  }
}
