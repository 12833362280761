@import '../../../theme/_main.scss';

.tab-warning-rdv {
  .tab-inner {
    display: block;
    width: 100%;
    h1 {
      font-style: normal;
      font-weight: 800;
      font-size: 35px;
      line-height: 42px;
      color: #353b7d;
      margin-top: 0;
    }
  }
  .tab-body {
    .body {
      display: flex;
      .table {
        width: 100%;

        th,
        td {
          text-align: center;
        }

        th {
          background-color: #e4e6e8;
        }

        .row {
          @include table-row-border-bottom;
          td {
            border-bottom: none;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            color: $table-row-color;
          }

          .col {
            &-delete {
              padding: 12px 0px 12px 0px;
              text-align: right;
              > div {
                background: $color-primary;
                border-radius: 50%;
                cursor: pointer;
              }
            }
            &-title {
              &:hover {
                background-color: $cell-hover-bg-color;
                cursor: pointer;
              }
            }
            &-action {
              > div {
                @include flex-center-center;
              }
              img {
                height: 22px;
                width: 22px;
              }
              span {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 30px;
                color: $table-row-color;
                margin-left: 3px;
              }
            }
          }
        }
      }
    }
  }
}
