@import '../../../../theme/main';

@mixin price-stat {
  .priceStat {
    width: 30%;
    text-align: center;

    font-size: 16px;
    p {
      margin: 10px;
    }

    fieldset {
      margin: auto;
      width: 73%;
      @include flex-center-center;
      padding: 5px;
      border-radius: 10px;
      border: 1px solid lightgray;
      input {
        outline: none;
        border: none;
        padding: 0;
        margin: 0;
        width: 100%;
        text-align: center;
        font-size: 16px;
        line-height: 30px;
        color: $color-primary;
      }
    }

    .sqmPrice {
      font-size: 0.9em;
      font-weight: 500;
    }

    &.error {
      fieldset {
        border: 2px solid $color-error;
        input {
          color: $color-error;
        }
      }
    }
  }
}

.prospecForm {
  flex: 1;
  display: flex;
  flex-direction: column;
  color: $color-primary;
  overflow: auto;

  &.noOverflow {
    overflow: hidden;
  }

  p {
    margin: 0;
  }
  h2 {
    font-weight: 700;
    font-size: 2rem;

    span {
      font-size: 1.125rem;
    }
  }

  .landType {
    width: 100%;
    margin-top: 10px;
    font-weight: 700;
    font-size: 1.5rem;
    text-align: center;
  }

  .prospecPriceStats {
    margin: 10px 0 5px 0;
    width: 100%;

    .commentContainer {
      background-color: #726fad1a;
      padding: 5px 10px;
      margin: 5px 0;

      p {
        span {
          font-size: 0.8em;
          font-style: italic;
        }
      }

      textarea {
        color: #354052;
        font-size: 14px;
        font-weight: 300;
        resize: none;

        &.error {
          border: 1px solid red;
          outline: 1px solid red;
          background-color: #ffecfc;
        }
      }
    }
    .stateContainer {
      width: 100%;
      @include flex-between-start;
      @include price-stat;
      p {
        font-weight: 700;
      }
    }

    .errorMessage {
      margin: 0;
      color: $color-error;
      font-style: italic;
      text-align: center;

      &Similar {
        margin-top: 60px;
      }
    }
    .estimationPriceContainer {
      @include price-stat;
      .priceStat {
        width: 100%;
        p {
          font-weight: 700;
        }
        fieldset {
          width: 50%;
        }
      }
    }
  }

  .prospecSimilarAdsContainer {
    button {
      @include button-base-blue;
      display: block;
      margin: auto;
      margin-bottom: 10px;
      height: 30px;

      &:disabled {
        background-color: $gray;
        border-color: $gray;
      }
    }
    .loaderContainer {
      padding-top: 100px;
    }
    .checkIndication {
      margin: 0;
      margin-bottom: 5px;
      text-align: center;
      font-size: 0.9rem;
      font-style: italic;
    }
    .errorMessage {
      margin: 0;
      margin-top: 20px;
      color: $color-error;
      font-style: italic;
      text-align: center;
      font-size: 1.2em;

      &Similar {
        margin-top: 60px;
        color: $color-error;
        font-style: italic;
        text-align: center;
        font-size: 1.2em;
      }
    }
    .tabsContainer {
      @include flex-center-center;
      box-shadow: 0 0 5px rgb(0, 0, 0, 20%);
      width: 99%;
      margin: auto;
      cursor: pointer;

      .tab {
        flex: 1;
        @include flex-center-center;
        text-align: center;
        border-top: 1px solid $background-grey;
        border-left: 1px solid $background-grey;
        border-bottom: 1px solid $background-grey;
        line-height: 50px;

        .adsTabLabel {
          margin-left: 3px;
        }
        .tabLoader {
          width: 20px;
          flex-grow: unset;
        }
        &:nth-child(3) {
          border-right: 1px solid $background-grey;
        }
        &:hover {
          border: 1px solid $gray;
          background-color: $background-grey;
        }
        &.active {
          line-height: 49px;
          border-bottom: 2px solid $color-primary;
          font-weight: 700;
        }
      }
    }

    .similaryAdsBody {
      > p {
        margin-top: 30px;
        font-size: 18px;
        text-align: center;
        line-height: 25px;
      }
      .similaryAdRow {
        position: relative;
        @include flex-start-center;
        padding: 2px;
        .adCheckbox {
          appearance: none;
          cursor: pointer;
          margin: 0 10px 0 0;
          font: inherit;
          color: $color_primary;
          width: 1.15em;
          height: 1.15em;
          border: 0.1em solid $color_primary;
          border-radius: 0.15em;
          transform: translateY(-0.075em);

          display: grid;
          place-content: center;
          &::before {
            content: '';
            width: 0.65em;
            height: 0.65em;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em var(--form-control-color);
          }
          &:checked::before {
            transform: scale(1);
            background-color: $color_primary;
            transform-origin: bottom left;
            clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
          }
        }
        .imageContainer {
          width: 60px;
          object-fit: cover;
          margin-right: 10px;
        }

        .rowInfos {
          @include flex-center-center;
          font-size: 0.92em;
          flex-direction: column;

          > div {
            width: 100%;
            margin: 2px 0;

            > span {
              padding: 0 5px;

              .label {
                font-weight: 700;
              }
            }
          }
        }
      }
    }
  }
}

.prospecDataError {
  height: 100%;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  color: $color_primary;

  h2 {
    margin-top: 100px;
    margin-bottom: 40px;
  }

  p {
    line-height: 23px;
    font-size: 1.1em;

    a {
      font-weight: 700;
      color: $color_primary;
      &:visited {
        color: $color_primary;
      }
    }
  }

  button {
    @include button-base-blue;
    margin-top: 40px;
    width: 200px;
  }

  h2,
  .supportLink,
  button {
    align-self: center;
  }
}
