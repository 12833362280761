@import '../../../theme/_main.scss';

.header {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  .logo {
    height: 40px;
    margin-left: 50px;
    cursor: pointer;
  }

  .btnContainer {
    flex: 1;
    @include flex-end-center;

    button {
      @include flex-center-center;
      height: 40px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #ffffff;
      background: #d2112f;
      border-radius: 2px;
      border: 1px #d2112f solid;
      cursor: pointer;
      margin-right: 50px;
      padding: 0 20px;

      svg {
        fill: #ffffff;
        margin-left: 10px;
      }

      &:enabled:hover {
        color: #d2112f;
        border: 1px #d2112f solid;
        background: #ffffff;

        svg {
          fill: #d2112f;
        }
      }

      &.viewBtn {
        margin-right: 5px;
        background-color: $color-primary;
        border: 1px $color-primary solid;
        width: 145px;

        &:disabled {
          color: $color-primary;
          border: 1px $color-primary solid;
          border-bottom: 4px #d2112f solid;
          background: #ffffff;
          cursor: default;
        }

        &:enabled:hover {
          color: $color-primary;
          border: 1px $color-primary solid;
          background: #ffffff;
        }
      }
    }
  }
}
