@import '../../../theme/main';

.MuiPickersLayout-toolbar {
  .MuiTypography-overline {
    display: none;
  }
}

.MuiPickersLayout-actionBar {
  justify-content: space-between;

  button {
    flex: 1;

    // cancel button is always the first button
    &:first-child {
      background-color: $color-error;
    }

    // optional Reset button at the middle
    &:nth-child(2) {
      background-color: $gray;
    }

    // ok button is always the last button
    // if only 2 button, this rule overrrite the  &:nth-child(2) rule
    &:last-child {
      background-color: $color-primary;
    }

    &:hover {
      font-weight: 700 !important;
      color: $white !important;
    }
  }
}
