@import '../../../theme/main';

$active-color: $white;
$inactive-color: $grey;

.genericTabContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 0;
  border-top-color: $grey;
  border-top-width: 4px;
  border-bottom-color: $white;
  border-bottom-width: 4px;
  border-style: solid;
  border-left: none;
  border-right: none;
  background-color: $inactive-color;

  .genericTab {
    flex: 1;
    line-height: 37px;
    text-align: center;
    cursor: pointer;
    background-color: $active-color;
    color: $color-primary;
    font-size: 1.1em;

    .genericTabContent {
      border-radius: 0;
      background-color: $inactive-color;
      @include flex-start-center;

      .label {
        flex: 1;
      }
      .tabIconContainer {
        @include flex-center-center;
        padding-right: 5px;

        .icon {
          border-radius: 50%;
          margin-left: -2px;

          &:hover {
            background-color: $inactive-color;
            transition: background-color 0.3s linear;
          }
        }
      }
    }

    &.selected {
      background-color: $inactive-color;

      .genericTabContent {
        background-color: $active-color;
        border-radius: 15px 15px 0 0;
        text-decoration: underline;
        text-decoration-color: $red;
        text-decoration-thickness: 2px;
        text-underline-offset: 8px;
      }
    }
    &.prev {
      .genericTabContent {
        border-radius: 0 0 15px 0;
      }
    }
    &.next {
      .genericTabContent {
        border-radius: 0 0 0 15px;
      }
    }

    // if first child, left radisus is 0
    &:first-child {
      &.selected {
        .genericTabContent {
          border-radius: 0 15px 0 0;
        }
      }
    }

    // if last child, left radisus is 0
    &:last-child {
      &.selected {
        .genericTabContent {
          border-radius: 15px 0 0 0;
        }
      }
    }
  }

  .addSeparation {
    border-left: 2px solid $active-color;
    border-right: 1px solid $active-color;
    height: 25px;
    margin-bottom: 4px;
    width: 0;
  }

  .addTab {
    width: 34px;
    margin-left: 5px;
    @include flex-center-center;
    text-align: center;
    cursor: pointer;
    background-color: $inactive-color;
    color: $color-primary;
    font-size: 1.1em;

    > div {
      @include flex-center-center;
      width: 30px;
      height: 30px;
      background-color: $inactive-color;
      border-radius: 50%;
      margin: 0 4px 4px 0;
      font-size: 1.2em;
      transition: background-color 0.1s linear;
      &:hover {
        background-color: $active-color;
        transition: background-color 0.3s linear;
      }
    }
  }
}
