@import '../../../../theme/main';

.addToFolderBtn {
  @include rounded-map-button;
  right: 20px;

  &.addToFolder {
    background: $color-primary;

    .numItems {
      margin: 0;
      position: absolute;
      top: -10px;
      right: 5px;
      width: 23px;
      height: 23px;
      background-color: $color-success;
      color: $white;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
    }
  }
}

.multiPlotSelectBtn {
  @include rounded-map-button;
  margin-top: 12px;
  right: 26.5vw;
  background: $white;

  &:hover {
    .btnTooltip {
      visibility: visible;
    }
  }

  img {
    height: 30px;
    cursor: pointer;
  }

  &.clicked {
    background: $color-primary;
  }
}

.multiSelectToolbar {
  @include toolbar_container;
}
