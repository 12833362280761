@import '../../../../../../theme/main';

@mixin altimetry-content-panel {
  background-color: rgba(255, 255, 255, 0.9);
  font-size: 1.5em;
  font-weight: bold;
  display: none;
  overflow: hidden;
  height: 318px;
  width: 600px;

  &.displayed {
    @include flex-center-center;
  }
}

.urbaAltimetryContainer {
  display: none;
  position: fixed;
  top: 140px;
  right: 105px;
  width: 600px;
  height: 350px;
  background-color: $white;
  box-shadow: 0 1px 6px #000;
  border-radius: 5px;

  &.displayed {
    display: block;
  }

  .header {
    width: 100%;
    height: 31px;
    display: flex;
    align-items: center;

    .urbaAltimetryHeaderInfoBtn {
      background-image: url('../../../../../../assets/images/toolbar/ElevationPathPanel.png');
      background-position: -91px 0;
      width: 31px;
      height: 31px;
    }

    .headerTitle {
      width: calc(100% - 60px);
      line-height: 31px;
      text-align: center;
      color: #c77a04;
      font-weight: 700;
      border-bottom: 1px solid #c77a04;
    }
  }

  .waitingContainer {
    @include altimetry-content-panel;

    .waitingText {
      font-size: 1.5em;
      font-weight: 700;
    }

    &.displayed {
      display: flex;
    }
  }

  .profilContainer {
    @include altimetry-content-panel;
    padding-left: 10px;
    align-items: center;
    justify-content: center;

    svg {
      font-weight: normal;
      line-height: normal;
      margin-top: 30px;
    }
    &.displayed {
      display: flex;
    }
  }

  .infosContainer {
    @include altimetry-content-panel;
    flex-direction: column;
    margin-top: -318px;
    z-index: 5;
    opacity: 0.8;
    .infoItem {
      color: #000;
      font-size: 0.8em;
      font-weight: normal;
    }
  }
}

#GPelevationPathPanel {
  width: 280px;
}

/* ELEVATION PATH DISPLAY */

#GPelevationPathProfil {
  height: 150px;
  width: inherit;
  line-height: normal; /* surcharge leaflet container */
}
