@import '../../../../../theme/main';

.pdfButtonContainer {
  position: relative;
  width: 100%;
  @include flex-center-center;
  justify-content: flex-end;

  .orpiOnly {
    display: none;
  }
  &.pdfButtonDouble {
    button {
      transition: width 0.4s linear;
      z-index: 2;
      &.orpiOnly {
        display: flex;
        position: absolute;
        right: 0;
        z-index: 1;
        width: 130px;
        opacity: 0;
        transition: right 0.4s linear;
      }
    }
  }
  &:hover {
    &.pdfButtonDouble {
      button {
        width: 130px;
        &.orpiOnly {
          opacity: 1;
          right: 132px;
        }
      }
    }
  }
}
