@import '../../../theme/_main.scss';

.tab-team {
  color: $color-primary;

  .urbn-loader-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: hsla(120, 13%, 10%, 0.7);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 10000;
    height: 100vh;
  }
  .tab-inner {
    display: flex;
    align-items: baseline;
    width: 100%;
    justify-content: space-between;
    h1 {
      font-style: normal;
      font-weight: 800;
      font-size: 35px;
      line-height: 42px;
      color: #353b7d;
      margin-top: 0;
    }
    button {
      font-size: 16px;
      width: 210px;
    }
    p {
      font-style: normal;
      font-weight: 300;
      font-size: 11px;
      line-height: 20px;
      text-align: center;
      color: #555980;
      margin-top: 5px;
    }
  }
  .tab-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .card {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      width: 330px;
      height: 480px;
      background: #ffffff;
      border: 1px solid #d8e3f2;
      box-sizing: border-box;
      margin-bottom: 26px;
      margin-right: 26px;
      padding: 30px;
      display: inline-block;
      border-radius: 5px;

      p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #555980;

        span {
          font-style: normal;
          font-weight: 800;
          font-size: 18px;
          line-height: 22px;
          color: #d2112f;
        }
      }

      &-header {
        .header-email {
          font-style: italic;
        }

        h3 {
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 20px;
          color: #555980;
          margin: 0px;
          height: 40px;
        }

        p {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          color: #555980;
          margin: 0;
        }
      }

      &-body {
        margin-top: 15px;

        .field {
          margin-bottom: 10px;

          .MuiFormControl-root {
            box-shadow: 0 0 3px 1px rgb(0 0 0 / 6%);
            border-color: #e4e6e8;
            background-color: #f1f4f8;

            .MuiInputLabel-outlined.MuiInputLabel-shrink {
              transform: translate(20px, 0px) scale(0.75);
            }

            .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
              border-color: $color-error;
            }

            p {
              margin-left: 3px;
              color: $color-error;
              font-style: italic;
              font-size: 10px;
              line-height: 12px;
            }

            .MuiOutlinedInput-adornedEnd {
              padding-right: 5px;
            }

            .MuiOutlinedInput-root {
              border-radius: 5px;
              background: #ffffff;
              border: 1px solid #e4e6e8;

              &:hover {
                color: #4e55a2;
              }

              button {
                padding-left: 0;
                padding-right: 0;
                color: $color-primary;
              }

              .MuiOutlinedInput-input {
                height: 30px;
                color: #4e55a2;
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 21px;
                background: transparent;
              }
            }

            .MuiInputLabel-outlined {
              color: #4e55a2;
              font-style: normal;
              font-weight: 300;
              font-size: 14px;
              background: transparent;
            }
          }
        }
      }

      .submit-btn {
        background: #bac6f2;
        border-radius: 2px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        color: #4e55a2;
        border: none;
        width: 100%;
        cursor: pointer;
        margin-top: 5px;
        height: 45px;

        &:hover {
          padding: 14px 0px;
          border: 1px solid #3861f6;
        }

        &:active {
          box-shadow: 0 0 5px #3861f6;
          font-weight: 700;
        }
      }

      &-footer {
        margin-top: 15px;
        color: $color-primary;

        &-edit {
          display: flex;

          .MuiFormControlLabel-labelPlacementStart {
            margin-left: 0px;
            margin-right: 0px;

            .MuiSwitch-switchBase {
              color: #a9b1bb;
            }

            .MuiSwitch-colorPrimary.Mui-checked {
              color: $color-primary;
            }

            .MuiTypography-body1 {
              font-style: normal;
              font-weight: bold;
              font-size: 14px;
              line-height: 20px;
            }
          }
          .switch {
            flex-grow: 1;
          }

          .icon {
            box-shadow: 0px 0px 4.03846px rgb(0 0 0 / 10%);
            border-radius: 4.03846px;
            cursor: pointer;
            padding: 8px 6px;
          }
        }

        &-role {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .MuiSwitch-root {
          }
          .MuiSwitch-colorPrimary.Mui-checked {
            color: $color-primary;
          }
          .MuiSwitch-colorPrimary.Mui-disabled {
            color: $logo-grey;
          }

          p {
            font-weight: bold;
            color: $color-primary;
          }
        }
      }
    }
  }
}
