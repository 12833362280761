@import '../../../theme/main';

.loaderContainer {
  @include flex-center-center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1100;
  height: 100vh;
  width: 100vw;
  background-color: $dark-opacity;
}
