@import '../../../../theme/main';

.right-panel-modal-body {
  .panel-content-body {
    .delete-button {
      background-color: #d2112f;
      border-color: #d2112f;
      width: 100%;
    }
  }
}
