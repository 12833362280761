@import '../../../theme/main';

.contentContainer {
  @include environment-container;

  input {
    cursor: pointer;
    margin: 0;
    margin-right: 10px;
  }

  .allServitudes {
    font-weight: 700;
    margin-bottom: 20px;

    .checkbox {
      @include flex-start-center;
    }
  }

  .servitudesContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .servitudeRow {
      .header {
        padding: 0 5px;
        margin-top: 1px;
        display: flex;
        align-items: center;

        &.openedHeader {
          background-color: $light-gray-bg;
          border-radius: 5px;
        }

        .isTitle {
          font-weight: 700;
        }

        label {
          flex-grow: 1;
        }

        .expandIcon {
          cursor: pointer;
        }
        .isOpen {
          transform: rotate(180deg);
        }
      }
      .childs {
        padding-left: 5px;
      }

      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        margin: 0;
      }
    }
  }
}
