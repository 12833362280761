@import '../../../theme/main';

.dashboardButton {
  position: fixed;
  top: 300px;
  right: -41px;
  z-index: 500;
  text-align: center;
  cursor: pointer;
  color: $white;
  background: $color-primary;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  border: none;
  padding: 14px 18px 25px 18px;
  transform: rotate(-90deg);
  display: flex;
  align-items: center;

  span {
    margin-left: 10px;
  }

  img {
    width: 23px;
    height: 23px;
    transform: rotate(90deg);
  }
}
